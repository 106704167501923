import { _Linkable } from 'prismic-types'
import { MenuButton } from '../menu-button/MenuButton'
import { FeaturedProps } from './Header'
import s from './Navbar.module.scss'
import { useUiState } from 'hooks/useUiState'
import { FocusEvent, MouseEvent } from 'react'

export type NavBarPropsChild = {
  groupLabel: string
  column: number
  items: { title: string; link: _Linkable }[]
}

export type NavBarPropsColumn = NavBarPropsChild[]
export type NavbarProps = {
  toplevel: {
    title: string
    ordering: number
    url: string
    children?: NavBarPropsColumn[]
    featured: FeaturedProps
  }[]
  toggleMenu: (event: MouseEvent | FocusEvent, i: number) => void
}

export const Navbar = ({ toplevel, toggleMenu }: NavbarProps) => {
  const { uiState } = useUiState()
  const { megaMenuIndex, isNavOpen } = uiState

  return (
    <nav className={s.navbar}>
      {toplevel?.map(({ title }, i) => {
        return (
          <MenuButton
            text={title}
            selected={!!isNavOpen && megaMenuIndex === i}
            key={`navbar-top-level-${i}`}
            toggleMenu={(event) => {
              toggleMenu(event, i)
            }}
          />
        )
      })}
    </nav>
  )
}
