import { BaseButton } from 'components/menu-button/MenuButton'
import { GlobeIcon } from 'components/icon/Icon'
import { useIntl } from 'react-intl'
import { SiteLocale } from 'utils/i18n'
import { useRouter } from 'next/router'

interface LanguageSwitcherProps {
  locale: SiteLocale
  isMobile: boolean | undefined
}

const LanguageSwitcher = ({ locale, isMobile }: LanguageSwitcherProps) => {
  const router = useRouter()

  const handleRoute = (locale: SiteLocale) => {
    router.push(`/`, undefined, {
      locale: locale === 'en' ? 'is' : 'en',
    })
    //  router.push(`${router.asPath}`, undefined, {
    //    locale: locale === 'en' ? 'is' : 'en',
    //  })
  }
  const { formatMessage } = useIntl()

  return (
    <BaseButton variant="clear" handleOnClick={() => handleRoute(locale)}>
      <GlobeIcon />
      {!isMobile ? (
        <span>
          {formatMessage({
            id: 'language_switch_label',
            defaultMessage: 'En',
          })}
        </span>
      ) : null}
    </BaseButton>
  )
}

export default LanguageSwitcher
