export type SiteLocale = 'is' | 'en'
export type PrismicLocale = 'is' | 'en-eu'

export const ALL_LOCALES: Array<SiteLocale> = ['is']

export const DEFAULT_LOCALE: SiteLocale = 'is'

export const PRISMIC_LOCALE: PrismicLocale =
  (process.env.PRISMIC_REPOSITORY_LOCALE as PrismicLocale | undefined) || 'is'

export function mapStringToSiteLocale(input?: string | unknown): SiteLocale {
  if (!input) {
    return 'is'
  }
  if (typeof input !== 'string') {
    return 'is'
  }

  const l = input.toLowerCase()

  if (l === 'en') {
    return 'en'
  }

  return 'is'
}

export const localeToPrismicLocale = (l?: string): PrismicLocale => {
  if (l === 'en') {
    return 'en-eu'
  }

  return 'is'
}

export function localeToLink(localeAsString?: string | unknown) {
  const locale = mapStringToSiteLocale(localeAsString)

  if (locale !== DEFAULT_LOCALE && ALL_LOCALES.indexOf(locale) >= 0) {
    return `/${locale}/`
  }

  return '/'
}

export const translations: Record<SiteLocale, { [key: string]: string }> = {
  is: {
    upload_choose_files: 'Veldu skrá',
    upload_no_files: 'Engin skrá',
    in_progress: 'Í vinnslu',
    installed_capacity: 'Uppsett afl',
    average_flow: 'Meðalrennsli á sek.',
    year_operational: 'Virkjunin opnaði',
    location: 'Staðsetning',
    invalid_type: '{name} er ekki rétt skráð',
    invalid_date: 'Dagsetning ekki gild',
    invalid_string: 'Gildi er ekki rétt skráð',
    invalid_ssn: 'Kennitala er ekki gild',
    invalid_tel: 'Símanúmer er ekki gilt',
    too_small: '{name} verður að vera {minimum} stafir',
    too_big: '{name} má ekki vera lengra en {maximum} stafir',
    string_too_small: '{name} Verður að vera {minimum} stafir',
    string_too_big: '{name} má ekki vera meira {maximum} stafir',
    string_missing: 'Fylla þarf út „{name}“',
    api_endpoint_url_missing: 'Undirliggjandi kerfi gat ekki tekið við erindi',
    unknow_error: 'Óþekkt villa kom upp.',
    form_uid_or_lang_missing: 'Tungumál og/eða auðkenni forms fannst ekki',
    form_not_found: 'Formaskráning fannst ekki',
    form_file_too_big: 'Fylgiskjal er of stórt, hámarksstærð er 4MB',
    form_submit_failed: 'Villa kom upp við að skrá erindi þitt',
    form_multer_unparsed:
      'Vefþjónninn lenti í vandræðum með að taka við forminu',
    checkbox_required: `
      Haka þarf við
        {name, select,
          undefined {reitinn}
          other {„{name}“}
        }.
    `,
    email_invalid_string: 'Netfang er ekki á réttu formi',
    address: 'Heimilisfang',
    city: 'Bæjarfélag',
    hours: 'Opnunartími',
    area: 'Landshluti',
    directions: 'Leiðarvísir',
    hero_see_more: 'Sjá nánar',
    my_sites: 'Mínar síður',
    title_404: 'Hér gæti verið meira stuð',
    subtitle_404: '404 - síða fannst ekki',
    text_404:
      'Við fundum ekki síðuna sem þú baðst um. Kannski var hún aldrei til eða mögulega týndum við henni.',
    go_to_slide: 'Fara á glæru {number}',
    play: 'Spila',
    pause: 'Pása',
    language_switch_label: 'En',

    charging_station_table_title: 'Hleðslustöðvar',
    charging_station_table__disabled_title: 'Væntanlegar hleðslustöðvar',
    charging_station_table_heading_1: 'Kennileiti',
    charging_station_table_heading_2: 'Heimilisfang',
    charging_station_table_heading_3: 'Lýsing',
    charging_station_table_heading_4: 'Afl hleðslustöðva',
    button_404: 'Fara á forsíðu',
    news_link: 'frettir',
    show_all_news: 'Sjá allar fréttir',
  },
  en: {
    invalid_ssn: 'Invalid ID Number',
    invalid_tel: 'Invalid phone number',
    string_missing: 'The field „{name}“ must be filled in',
    api_endpoint_url_missing: 'We were unable to submit your request.',
    unknow_error: 'An unknown error occurred.',
    form_uid_or_lang_missing: 'Language and/or form identifier was not found',
    form_not_found: 'Form registration not found',
    form_file_too_big: 'Attachment is too large (maximum size is 4MB)',
    form_submit_failed: 'An error occurred while submitting your request',
    form_multer_unparsed:
      'The web server encountered problems receiving the form',
    checkbox_required: `
      Please mark the checkbox for 
        {name, select,
          undefined {reitinn}
          other {„{name}“}
        }.
    `,
    address: 'Address',
    city: 'City / Town',
    hours: 'Opening hours',
    area: 'Region',
    directions: 'Directions',
    hero_see_more: 'Find out more',
    my_sites: 'Customer Portal',
    title_404: "Sorry - that page doesn't exist",
    subtitle_404: 'Error 404 - Page Not Found',
    text_404:
      "We couldn't find the page you are looking for. Maybe it never existed or possibly we lost it along the way.",
    upload_choose_files: 'Choose a file',
    upload_no_files: 'No file chosen',
    in_progress: 'In progress',
    installed_capacity: 'Electrical capacity',
    average_flow: 'Average flow (m³/sec)',
    year_operational: 'Operating since',
    location: 'Location',
    invalid_type: '{name} is invalid',
    invalid_literal: '',
    invalid_union: '',
    invalid_union_discriminator: '',
    invalid_enum_value: '',
    unrecognized_keys: '',
    invalid_arguments: '',
    invalid_return_type: '',
    invalid_date: 'Date is not valid',
    invalid_string: 'Value is not correctly entered',
    too_small: '{name} must be at least {minimum} characters',
    too_big: '{name} must not exceed {maximum} characters',
    string_too_small: '{name} must be at least {minimum} characters',
    string_too_big: '{name} must not exceed {maximum} characters',
    email_invalid_string: 'Email is not in a valid format',
    go_to_slide: 'Go to slide {number}',
    play: 'Play',
    pause: 'Pause',
    language_switch_label: 'IS',
    charging_station_table_title: 'Charging stations',
    charging_station_table__disabled_title: 'Expected charging stations',
    charging_station_table_1: 'Landmarks',
    charging_station_table_2: 'Address',
    charging_station_table_3: 'Description',
    charging_station_table_4: 'Power of charging stations',
    button_404: 'Back to frontpage',
    news_link: 'news',
    show_all_news: 'See all news',
  },
}
