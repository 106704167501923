import styled from 'styled-components'
import { MappedNavigation } from './Header'
import { linkResolver } from 'prismic/linkResolver'
import { Link } from 'components/link/Link'
import { motion, useAnimate } from 'framer-motion'
import { useEffect, useState } from 'react'
import { BaseMenuButton } from 'components/menu-button/MenuButton'
import { MegaMenuFeatured } from 'components/mega-menu/MegaMenuFeatured'
import { useUiState } from 'hooks/useUiState'
import { ArrowLeft } from 'components/icon/Icon'

const MobileSublist = ({
  items,
  title,
  featured,
  className,
  handleClose,
}: Omit<MappedNavigation, 'ordering'> & {
  className?: string
  handleClose: () => void
}) => {
  const [clsName, setClsName] = useState('')
  useEffect(() => setClsName(className || ''), [className])
  return (
    <div className={clsName}>
      <button className="section-title" onClick={() => handleClose()}>
        <div className="icon">
          <ArrowLeft />
        </div>
        <span>{title}</span>
      </button>
      <div>
        <div className="groups">
          {items.flat().map((item) => {
            return (
              <div className="group" key={item.groupLabel}>
                <div className="group-title">{item.groupLabel}</div>
                <ul className="group-items">
                  {item.items.map((item) => {
                    return (
                      <li key={item.title} className="group-item">
                        <Link to={linkResolver(item.link)}>{item.title}</Link>
                      </li>
                    )
                  })}
                </ul>
              </div>
            )
          })}
        </div>

        {featured.heading ? (
          <div className="featured-wrapper">
            <MegaMenuFeatured featured={featured} />
          </div>
        ) : null}
      </div>
    </div>
  )
}

const StyledMobileSublist = styled(MobileSublist)`
  display: flex;
  flex-direction: column;

  position: absolute;
  inset: 0;

  margin: 8px 24px 24px;

  overflow-y: auto;

  border-radius: 16px;

  opacity: 0;

  background: var(--ui-highlights-higlight-fill, #ebe8ed);

  transform: translateX(110%);

  .section-title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;

    position: sticky;
    top: 0;

    padding: 24px;

    width: 100%;

    font-size: 18px;
    font-weight: 800;

    .icon {
      width: 16px;
    }

    background: linear-gradient(
      180deg,
      #ebe8ed 90%,
      rgba(235, 232, 237, 0) 100%
    );
    color: #320564b8;
  }

  .groups {
    display: flex;
    flex-direction: column;
    gap: 32px;

    padding: 0 16px 32px;
  }

  .group {
    display: flex;
    flex-direction: column;
    gap: 16px;

    padding: 8px 0;
    .group-title {
      font-size: 24px;
      font-weight: 800;

      color: var(--text-text-purple, #320564);
    }

    .group-items {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    .group-item {
      font-size: 20px;
      font-weight: 400;

      color: var(--text-text-purple, #320564);
      a {
        text-decoration: none;
      }
    }
  }

  .featured-wrapper {
    padding: 0 16px 16px;
  }
`

const StyledMobileNavbar = styled.div`
  display: block;

  position: absolute;
  inset: 64px 0 0 0;

  padding: 16px 24px;

  height: calc(100dvh - 64px);

  backdrop-filter: blur(6px);
  background: rgba(255, 255, 255, 0.92);

  .mobile-nav-list {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .item {
    font-weight: 700;
    font-size: 28px;
    line-height: 1.1;

    color: var(--os-palette-primary);

    button {
      padding: 16px;

      border-radius: 8px;

      &:hover,
      &:focus,
      &:active {
        background: var(--os-color-bkg-button-clear-focus);
      }
    }
  }
`

const useMenuAnimation = (isDrawerOpen: boolean) => {
  const [scope, animate] = useAnimate()

  useEffect(() => {
    const drawerAnimations = isDrawerOpen
      ? [
          ['.mobile-nav-list+div', { opacity: 1 }, { duration: 0 }],
          [
            '.mobile-nav-list+div',
            { transform: 'translateX(0%)' },
            { at: '<' },
          ],
        ]
      : [
          ['.mobile-nav-list+div', { transform: 'translateX(100dvw' }],
          ['.mobile-nav-list+div', { opacity: 0 }],
        ]

    animate([...drawerAnimations] as any)
  }, [isDrawerOpen, animate])

  return scope
}

const NavBar = motion(StyledMobileNavbar)

export const MobileNavbar = ({ items }: { items: MappedNavigation[] }) => {
  const [isDrawerOpen, setDrawerOpen] = useState(false)
  const [drawerIndex, setDrawerIndex] = useState(0)
  const { uiState, setUIState } = useUiState()
  const scope = useMenuAnimation(isDrawerOpen)
  const [open, setOpen] = useState(false)

  const handleClose = () => {
    setDrawerOpen(false)
  }

  useEffect(() => {
    setOpen(Boolean(uiState.isNavOpen))
  }, [uiState.isNavOpen])

  return (
    <NavBar
      ref={scope}
      variants={{
        closed: { transform: 'translateX(100dvw)' },
        open: { transform: 'translateX(0dvw)' },
      }}
      initial="closed"
      animate={open ? 'open' : 'closed'}
    >
      <ul className="mobile-nav-list">
        {items.map((item, i) => {
          return (
            <li className="item" key={item.title}>
              <BaseMenuButton
                onClick={() => {
                  setDrawerOpen(!isDrawerOpen)
                  setDrawerIndex(i)
                }}
              >
                {item.title}
              </BaseMenuButton>
            </li>
          )
        })}
      </ul>
      <StyledMobileSublist
        items={items[drawerIndex].items}
        featured={items[drawerIndex].featured}
        title={items[drawerIndex].title}
        handleClose={handleClose}
      />
    </NavBar>
  )
}
