import { useEffect, useRef } from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import classNames from 'classnames/bind';
import { Router } from 'next/router';

import { Loading } from 'components/loading/Loading';

import { useUiState } from 'hooks/useUiState';

import s from './PageTransition.module.scss';

const c = classNames.bind(s);

type PageTransitionProps = {
  route: string;
  animate?: boolean; // optional to add a custom animation (td. a wipe effect), if false, defaults to a simple fade
  children: React.ReactNode;
};

type NextJsRouteError = Error & { cancelled?: boolean };

const PAGE_TRANSITION_DURATION = 1000;

export const PageTransition = ({ route, children, animate }: PageTransitionProps) => {
  const cleanRoute = route.split(/[#?]/g)[0];
  const prevPath = useRef(cleanRoute);
  const { uiState, setUIState } = useUiState();
  const nodeRef = useRef<HTMLDivElement>(null);

  const transitionDisabled = !uiState.canTransition || uiState.prefersReducedMotion;

  const handleStart = () => {
    if (!transitionDisabled) {
      setUIState({ canScroll: false, isNavOpen: false });
    }
  };

  const handleEnter = () => {
    // console.log('entering');
  };

  const handleEntering = () => {
    // console.log('entering');
    if (!transitionDisabled) {
      window.scrollTo(0, 0);
    }
  };

  const handleExiting = () => {
    // console.log('exiting');
  };

  const handleExited = () => {
    // console.log('exited');
  };

  const handleEntered = () => {
    setUIState({
      canTransition: false,
      canScroll: true,
    });
  };

  useEffect(() => {
    function handleRouteStart(url: string) {
      // Only activate loading state when actual route changes
      const pathname = url.split(/[#?]/g)[0];
      if (prevPath.current !== pathname) {
        setUIState({ isLoading: true });
      }

      prevPath.current = pathname;
    }

    function handleRouteComplete(err?: NextJsRouteError) {
      // hide loading screen
      setUIState({ isLoading: false });

      // do something for if action is cancelled?
      if (err && err.cancelled) {
        console.info('cancelled');
      }
    }

    Router.events.on('routeChangeStart', handleRouteStart);
    Router.events.on('routeChangeComplete', handleRouteComplete);
    Router.events.on('routeChangeError', (err: NextJsRouteError) =>
      handleRouteComplete(err)
    );

    return () => {
      Router.events.off('routeChangeStart', handleRouteStart);
      Router.events.off('routeChangeComplete', handleRouteComplete);
      Router.events.off('routeChangeError', handleRouteComplete);
    };
  }, [setUIState]);

  const transitionStyle = {
    '--ptd': uiState.prefersReducedMotion ? '1ms' : `${PAGE_TRANSITION_DURATION}ms`,
  } as React.CSSProperties;

  const content = animate ? (
    <>
      <div className={s.pageTransition__inner}>{children}</div>
      {/* use this to animate a transition */}
      <div className={s.pageTransition__animationEl} />
    </>
  ) : (
    children
  );

  return (
    <>
      <SwitchTransition>
        <CSSTransition
          nodeRef={nodeRef}
          key={cleanRoute}
          onExit={handleStart}
          onExiting={handleExiting}
          onExited={handleExited}
          onEnter={handleEnter}
          onEntering={handleEntering}
          onEntered={handleEntered}
          addEndListener={(done) => {
            // important: this listener fails if there is no css transition for exitActive & enterActive
            // use timeout instead to explicitly determine transition end
            nodeRef.current?.addEventListener('transitionend', done, false);
          }}
          timeout={transitionDisabled ? 0 : undefined}
          classNames={{ ...s }}
        >
          <div
            className={c(s.pageTransition, { animate })}
            style={transitionStyle}
            ref={nodeRef}
          >
            {content}
          </div>
        </CSSTransition>
      </SwitchTransition>

      <Loading />
    </>
  );
};
