import { UIProvider } from 'context/ui'
import { AppProps as NextAppProps } from 'next/app'
import { PrismicLink } from 'prismic/types/link'
import {
  Maybe,
  Navigation,
  NavigationConnectionEdge,
  Layout as PrismicLayout,
  Page as PrismicPage,
} from 'prismic-types'

import { Devtools } from 'components/devtools/Devtools'
import { Layout } from 'components/layout/Layout'
import { Meta } from 'containers/meta/Meta'

import 'styles/global.scss'

type InheritedPageProps = {
  layout?: PrismicLayout | null
  page?: PrismicPage | null
  preview?: boolean
  onboardingButtonLink?: PrismicLink
  navigation: (Maybe<Navigation> | undefined)[]
}

type AppProps<P> = {
  pageProps: P
} & Omit<NextAppProps<P>, 'pageProps'>

export default function App({
  Component,
  pageProps,
}: AppProps<InheritedPageProps>) {
  const isDev = process.env.NODE_ENV === 'development'
  const layout = pageProps.layout as PrismicLayout | null
  const navigation = pageProps.navigation

  // Check if SideButton should be shown on current page
  const onboardingLink = pageProps.onboardingButtonLink ?? undefined

  return (
    <>
      <Meta />
      <UIProvider>
        <Layout
          layout={layout}
          preview={pageProps.preview ?? false}
          onboardingButtonLink={onboardingLink}
          navigation={navigation}
        >
          <Component {...pageProps} />
        </Layout>
      </UIProvider>
      {isDev && <Devtools />}
    </>
  )
}
