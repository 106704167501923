import { useEffect } from 'react'
import { IntlProvider } from 'react-intl'
import { useRouter } from 'next/router'
import { PrismicLink } from 'prismic/types/link'
import { Maybe, Navigation, Layout as PrismicLayout } from 'prismic-types'
import { Header } from 'components/header/Header'

import { LiveChatButton } from 'components/live-chat/LiveChatButton'
import { PageTransition } from 'components/page-transition/PageTransition'
import { PreviewNotice } from 'components/preview-notice/PreviewNotice'
import { SvgPaths } from 'components/svg/Svg'
import { FooterContainer } from 'containers/footer/FooterContainer'
import { TrackingMeta } from 'containers/meta/TrackingMeta'

import { useUiState } from 'hooks/useUiState'
import { DEFAULT_LOCALE, mapStringToSiteLocale, translations } from 'utils/i18n'

import s from './Layout.module.scss'

type LayoutProps = {
  children: React.ReactNode
  layout: PrismicLayout | null
  preview?: boolean
  onboardingButtonLink?: PrismicLink
  navigation: (Maybe<Navigation> | undefined)[]
}

export const Layout = ({
  children,
  layout,
  navigation,
  onboardingButtonLink,
  preview,
}: LayoutProps) => {
  const { setUIState } = useUiState()
  const router = useRouter()
  const locale = mapStringToSiteLocale(router.locale)

  useEffect(() => {
    setUIState({ isNavOpen: false })
  }, [router, setUIState])

  useEffect(() => {
    const scrollEl = document.createElement('div')
    scrollEl.style.cssText =
      'position:absolute;top:-9999px;width:50px;height:50px;overflow:scroll;'
    document.body.appendChild(scrollEl)
    const scrollbarWidth =
      scrollEl.getBoundingClientRect().width - scrollEl.clientWidth
    document.body.removeChild(scrollEl)
    document.documentElement.style.setProperty(
      '--scrollbar-width',
      scrollbarWidth + 'px'
    )
  }, [])
  return (
    <IntlProvider
      locale={locale}
      messages={translations[locale]}
      defaultLocale={DEFAULT_LOCALE}
    >
      <PageTransition animate={false} route={router.asPath}>
        <div className={s.layout}>
          <Header navigation={navigation} locale={locale} />

          <main id="main">{children}</main>
          {/* {onboardingButtonLink && (
            <SideButton
              text="Komdu í viðskipti"
              link={linkResolver(onboardingButtonLink)}
            />
          )} */}
          <FooterContainer
            contactTitle={layout?.title ?? []}
            leftTextfield={layout?.left_textfield ?? []}
            rightTextfield={layout?.right_textfield ?? []}
            socialLinks={layout?.social_links ?? []}
            slices={layout?.slicesFooter ?? []}
            bottom={layout?.bottom ?? []}
            certifications={layout?.bottom_certifications ?? []}
          />
        </div>
        {preview && <PreviewNotice />}
      </PageTransition>
      <div id="modal-container" />
      <SvgPaths />
      <LiveChatButton />
      <TrackingMeta />
    </IntlProvider>
  )
}
