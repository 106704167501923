import { _Linkable } from 'prismic-types'
import { Link } from '../link/Link'
import s from './MegaMenu.module.scss'
import { MotionProps, motion } from 'framer-motion'
import { linkResolver } from 'prismic/linkResolver'
import { v4 as uuidv4 } from 'uuid'
export type MegaMenuGroupProps = {
  title: string
  links: Array<{
    title: string
    link: _Linkable
  }>
}

const group = {
  variants: {
    closed: { y: -16, opacity: 0 },
    open: { y: 0, opacity: 1 },
  },
  transition: { opacity: { duration: 0.2 }, y: { duration: 0.2 } },
} satisfies MotionProps

export const MegaMenuGroup = ({ title, links }: MegaMenuGroupProps) => {
  const uid = uuidv4()
  return (
    <motion.div
      className={s.group}
      variants={group.variants}
      initial="closed"
      key={`${title}-${uid}`}
    >
      <h3 className={s.group__title}>{title}</h3>
      <ul>
        {links.map((link) => (
          <MegaMenuLinkItem
            key={`${link.title}-${uid}`}
            title={link.title}
            link={link.link}
          />
        ))}
      </ul>
    </motion.div>
  )
}

const MegaMenuLinkItem = ({
  title,
  link,
}: {
  title: string
  link: _Linkable
}) => {
  const url = linkResolver(link)
  return (
    <li className={s.linkItem}>
      <Link to={url}>{title}</Link>
    </li>
  )
}
