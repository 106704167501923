import { MouseEvent, useState } from 'react'
import s from './MenuButton.module.scss'
import styled from 'styled-components'
import { useUiState } from 'hooks/useUiState'

type MenuButtonProps = {
  text: string
  selected: boolean
  toggleMenu: (event: MouseEvent) => void
}

export const MenuButton = ({
  text,
  selected = false,
  toggleMenu,
}: MenuButtonProps) => {
  const { uiState } = useUiState();
  const [onHoverTimeout, setOnHoverTimeout] = useState<ReturnType<typeof setTimeout> | null>(null);
  const HOVER_DELAY = 150;

  const handleClick = (event: MouseEvent) => {
    toggleMenu(event)
  }

  const handleFocus = (event: any) => {
    toggleMenu(event)
  }
  
  const startHoverTimeout = (event : MouseEvent, isNavOpen : boolean | undefined) => {
    if(isNavOpen) {
      setOnHoverTimeout(setTimeout(() => {
        handleClick(event);
        setOnHoverTimeout(null);
      }, HOVER_DELAY));
    } else {
      handleClick(event);
    }
  }

  const cancelHoverTimeout = (onHoverTimeout : ReturnType<typeof setTimeout> | null) => {
    if(onHoverTimeout !== null) {
      clearTimeout((onHoverTimeout as unknown as number));
      setOnHoverTimeout(null);
    }
  }

  return (
    <button
      onClick={handleClick}
      onMouseEnter={(event) => startHoverTimeout(event, uiState.isNavOpen)}
      onMouseLeave={() => cancelHoverTimeout(onHoverTimeout)}
      onFocus={handleFocus}
      className={`${s.menuButton} ${selected ? s.selected : ''}`}
    >
      {text}
    </button>
  )
}

type BaseButtonProps = {
  children: React.ReactNode
  variant?: 'primary' | 'clear'
  handleOnClick?: () => void
}

export const BaseButton = ({
  children,
  variant,
  handleOnClick,
}: BaseButtonProps) => {
  const v = variant ? `-${variant}` : ''
  /* prettier-ignore */
  const StyledBaseButton = styled.button`
    font-weight: 500;
    padding: var(--os-padding-button${v});
    border-radius: var(--os-radius-medium, 8px);
    background: var(--os-color-bkg-button${v});
    color: var(--os-color-text-button${v});
    // transition quick spring for 600ms with stiffness of 300, damping of 20 and mass of 1
    transition: background 300ms,
      padding 600ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 0ms;

    &:hover {
      background: var(--os-color-bkg-button${v}-hover);
      padding: var(--os-padding-button${v}-hover);
    }

    &:focus,
    &.selected {
      background: var(--os-color-bkg-button${v}-focus);
      padding: var(--os-padding-button${v}-focus);
    }

    &:active {
      background: var(--os-color-bkg-button${v}-active);
      padding: var(--os-padding-button${v}-active);
    }

    &:focus-visible {
      outline: 2px solid var(--os-color-outline);
    }
  `

  return <StyledBaseButton onClick={handleOnClick}>{children}</StyledBaseButton>
}

export const BaseMenuButton = (props: any) => {
  return <button {...props}>{props.children}</button>
}
