/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react'

import { Button } from 'components/button/Button'
import { Modal } from 'components/modal/Modal'

import s from './LiveChatButton.module.scss'

const liveChatId = process.env.NEXT_PUBLIC_LIVE_CHAT_LICENSE
type WindowWithLivechat = typeof window & {
  LiveChatWidget?: any
  cookiehub?: any
}

export const LiveChatButton = () => {
  const win =
    typeof window !== 'undefined' ? (window as WindowWithLivechat) : undefined
  const [isAvailable, setIsAvailable] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isActive, setIsActive] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [cookiehubError, setCookiehubError] = useState(false)

  const updateAvailable = () => {
    setIsAvailable(true)
  }

  useEffect(() => {
    win?.addEventListener('analytics', updateAvailable)

    setTimeout(() => {
      if (win && win.cookiehub && win.cookiehub.hasConsented('analytics')) {
        setIsAvailable(true)
      }
    }, 1500)

    return () => {
      win?.removeEventListener('analytics', updateAvailable)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalOpen, isLoading])

  if (!liveChatId) {
    return null
  }

  const openChat = () => {
    setIsLoading(true)
    if (win && isAvailable) {
      win.LiveChatWidget?.init()
      win.LiveChatWidget?.call('maximize')
      setTimeout(() => {
        setIsActive(true)
      }, 1500)
    } else {
      setModalOpen(true)
    }
    setTimeout(() => {
      setIsLoading(false)
    }, 1500)
  }

  return isActive ? null : (
    <>
      <button
        className={s.liveChatButton}
        onClick={openChat}
        aria-label="Opna spjall"
      >
        {isLoading ? (
          <svg
            enableBackground="new 0 0 48 48"
            viewBox="0 0 48 48"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g fill="#fff">
              <circle cx="16.22094" cy="24" r="1.47609" />
              <circle cx="24" cy="24" r="1.47609" />
              <circle cx="31.77906" cy="24" r="1.47609" />
            </g>
          </svg>
        ) : (
          <svg
            color="inherit"
            viewBox="0 0 32 32"
            className={s.liveChatButton__icon}
          >
            <path
              fill="var(--color-secondary)"
              d="M12.63,26.46H8.83a6.61,6.61,0,0,1-6.65-6.07,89.05,89.05,0,0,1,0-11.2A6.5,6.5,0,0,1,8.23,3.25a121.62,121.62,0,0,1,15.51,0A6.51,6.51,0,0,1,29.8,9.19a77.53,77.53,0,0,1,0,11.2,6.61,6.61,0,0,1-6.66,6.07H19.48L12.63,31V26.46"
            />

            <path
              d="M8,18c-1.1,0-2-0.9-2-2s0.9-2,2-2s2,0.9,2,2S9.1,18,8,18z M16,18c-1.1,0-2-0.9-2-2s0.9-2,2-2
        s2,0.9,2,2S17.1,18,16,18z M24,18c-1.1,0-2-0.9-2-2s0.9-2,2-2s2,0.9,2,2S25.1,18,24,18z"
            />

            <path
              fill="currentColor"
              d="M19.57,21.68h3.67a2.08,2.08,0,0,0,2.11-1.81,89.86,89.86,0,0,0,0-10.38,1.9,1.9,0,0,0-1.84-1.74,113.15,113.15,0,0,0-15,0A1.9,1.9,0,0,0,6.71,9.49a74.92,74.92,0,0,0-.06,10.38,2,2,0,0,0,2.1,1.81h3.81V26.5Z"
              className="css-1adcsh3 e1nep2br0"
            />
          </svg>
        )}
      </button>
      <Modal
        className={s.liveChatButton__modal}
        active={modalOpen}
        onClose={() => setModalOpen(false)}
      >
        <div className={s.liveChatButton__modalWrap}>
          <p>
            Til þess að virkja netspjallið þarft þú að veita leyfi fyrir notkun
            á kökum („Tölfræðikökur“), þar sem þær eru forsenda þess að spjallið
            virki rétt.
          </p>
          {!cookiehubError ? (
            <Button
              onClick={() => {
                if (win && win.cookiehub) {
                  win.cookiehub.openSettings()
                  setModalOpen(false)
                } else {
                  setCookiehubError(true)
                }
              }}
            >
              Virkja netspjall
            </Button>
          ) : (
            <p className={s.liveChatButton__cookiehubError}>
              Villa: ekki hægt að sækja kökustillingar. Mögulega vegna adblocker
              eða álíka.
            </p>
          )}
        </div>
      </Modal>
    </>
  )
}
