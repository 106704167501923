import { motion, useAnimate } from 'framer-motion'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useUiState } from 'hooks/useUiState'

const StyledNavButton = styled.button`
  svg {
    width: 100%;
    height: 100%;
  }
`

const Line = (props: any) => <line {...props} />

export const NavButton = () => {
  const [scope, animate] = useAnimate()
  const { uiState, setUIState } = useUiState()
  const [isNavOpen, setIsNavOpen] = useState(false)

  const toggle = () => {
    setUIState({ isNavOpen: !uiState.isNavOpen })
    setIsNavOpen(!isNavOpen)
  }

  useEffect(() => {
    const animation = isNavOpen
      ? () => {
          animate(
            '#navButtonLineTop',
            {
              x1: '6.41421',
              y1: '7',
              x2: '21.9606',
              y2: '22.5563',
            },
            { duration: 0.3 }
          )
          animate(
            '#navButtonLineMiddle',
            {
              x1: '13',
              y1: '15.25',
              x2: '13',
              y2: '15.25',
            },
            { duration: 0.3 }
          )
          animate(
            '#navButtonLineBottom',
            {
              x1: '6',
              y1: '22.5565',
              x2: '21.5563',
              y2: '7.00014',
            },
            { duration: 0.3 }
          )
        }
      : () => {
          animate(
            '#navButtonLineTop',
            {
              x1: '3',
              y1: '6.75',
              x2: '25',
              y2: '6.75',
            },
            { duration: 0.3 }
          )
          animate(
            '#navButtonLineMiddle',
            {
              x1: '3',
              y1: '15.25',
              x2: '25',
              y2: '15.25',
            },
            { duration: 0.3 }
          )
          animate(
            '#navButtonLineBottom',
            {
              x1: '3',
              y1: '23.75',
              x2: '25',
              y2: '23.75',
            },
            { duration: 0.3 }
          )
        }

    animation()
  }, [isNavOpen, animate])

  return (
    <StyledNavButton ref={scope} onClick={toggle}>
      <svg viewBox="0 0 28 30" xmlns="http://www.w3.org/2000/svg">
        <Line
          id="navButtonLineTop"
          x1="3"
          y1="6.75"
          x2="25"
          y2="6.75"
          stroke="#320564"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <Line
          id="navButtonLineMiddle"
          x1="3"
          y1="15.25"
          x2="25"
          y2="15.25"
          stroke="#320564"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <Line
          id="navButtonLineBottom"
          x1="3"
          y1="23.75"
          x2="25"
          y2="23.75"
          stroke="#320564"
          strokeWidth="2"
          strokeLinecap="round"
        />
      </svg>
    </StyledNavButton>
  )
}
